<template>
  <div class="container-fluid px-lg-5 mb-3 margin-top-0421">
    <div class="row">
      <div class="col-md-6 offset-md-3">
        <VerifySteps phone="true" email="true" progress="100" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 offset-md-3">
        <h1>Send code</h1>
        <ErrorFormField
          :msg-array="$data.error.common"
          :msg-type="$data.result.status"
          v-if="$data.result.status != ''"
        />
        <div class="mb-3">
          <input
            type="text"
            class="form-control"
            id="mobileNumber"
            placeholder="Enter your mobile number"
          />
        </div>
        <ErrorFormField
          :msg-array="$data.error.mobileNumber"
          v-if="$data.result.status == 'error'"
        />
        <div class="mt-3">
          <LoadingIcons v-if="$data.ajax.isSendingCode" />
          <button
            class="btn btn-primary"
            id="btnSendCode"
            @click="btnSendCodeClick"
            type="submit"
            v-else
          >
            Send
          </button>
        </div>
      </div>
      <div class="col-md-6 offset-md-3">
        <h1>Verify your mobile number</h1>

        <div class="input-group">
          <span class="input-group-text">
            <i class="fas fa-sms text-danger"></i>
          </span>
          <input
            type="text"
            class="form-control"
            id="verifyCode"
            @change="tokenChangeHandle"
            v-model="token"
            placeholder="Verify Code"
          />
        </div>
        <ErrorFormField :msg-array="$data.error.verifyCode" v-if="$data.result.status == 'error'" />
        <div class="mt-3">
          <LoadingIcons v-if="$data.ajax.isVerifying" />
          <button
            class="btn btn-primary"
            id="btnLoginModal"
            @click="btnVerifyClick"
            type="submit"
            v-else
          >
            Verify
          </button>
        </div>
        <div v-if="$data.ajax.isVerifying"></div>
        <div v-else>
          <ErrorFormField :msg-type="$data.result.status" :msg-array="$data.result.messages" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingIcons from '../../common/LoadingIcons.vue';
import ErrorFormField from '../../common/error/ErrorFormField.vue';
import VerifySteps from './VerifySteps';
// import Cookies from 'js-cookie';
import intlTelInput from 'intl-tel-input';

export default {
  name: 'PhoneVerify',
  components: { LoadingIcons, ErrorFormField, VerifySteps },
  data: function () {
    return {
      // qa: [],
      // code: '',
      ajax: {
        isSendingCode: false,
        isVerifying: false,
      },
      error: {
        common: [],
        mobileNumber: [],
        verifyCode: [],
      },
      result: {
        status: '',
        messages: [],
      },
    };
  },
  created() {},
  methods: {
    btnSendCodeClick: async function () {
      let self = this;
      self.ajax.isSendingCode = true;
      self.$data.error.common = [];
      self.$data.error.mobileNumber = [];
      var input = document.getElementById('mobileNumber');
      var iti = window.intlTelInputGlobals.getInstance(input);
      let selectedCountryData = iti.getSelectedCountryData(); // etc
      var number = input.value;
      if (number == '') {
        self.$data.result.status = 'error';
        self.$data.error.mobileNumber.push('This field is required.');
        self.ajax.isSendingCode = false;
        return false;
      }
      // console.log('select country data diacode', selectedCountryData.dialCode);
      // console.log('select country data country code 2 ', selectedCountryData.iso2);
      // console.log('number ', number);
      let token = this.$store.state.token;
      let headersObject = new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      });

      let objData = {
        country_code: selectedCountryData.iso2,
        country_dial_code: selectedCountryData.dialCode,
        mobile_number: number,
      };
      const formData = Object.keys(objData)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(objData[key]))
        .join('&');
      let apiUrl = process.env.VUE_APP_API_URL;
      await fetch(apiUrl + 'user/get-phone-verify-code', {
        method: 'POST',
        headers: headersObject,
        body: formData,
      })
        .then((response) => response.json())
        .then((resData) => {
          console.log('Success:', resData);
          let messages = resData.messages;
          if (resData.status == 'error') {
            self.$data.result.status = resData.status;
            if (messages.common.length > 0) self.$data.error.common = messages.common;
            else self.$data.error.common = [];
            //eslint-disable-next-line
            if (messages.hasOwnProperty('mobileNumber')) {
              self.$data.error.mobileNumber = messages.mobileNumber;
            }
            console.log('self.$data ', self.$data);
            self.ajax.isSendingCode = false;
            return false;
          }

          self.$data.result.status = resData.status;
          //eslint-disable-next-line
          if (messages.hasOwnProperty('common')) {
            self.$data.error.common = messages.common;
          }
          self.ajax.isSendingCode = false;
        })
        .catch(function (err) {
          console.log('error', err);
          self.ajax.isSendingCode = false;
        });
    },

    tokenChangeHandle: function () {
      this.$data.token = document.getElementById('verifyCode').value;
    },
    btnVerifyClick: async function () {
      let self = this;
      self.ajax.isVerifying = true;
      self.$data.error.common = [];
      self.$data.error.verifyCode = [];
      var input = document.getElementById('verifyCode');
      var code = input.value;
      if (code == '') {
        self.$data.result.status = 'error';
        self.$data.error.verifyCode.push('This field is required.');
        self.ajax.isVerifying = false;
        return false;
      }
      // console.log('select country data diacode', selectedCountryData.dialCode);
      // console.log('select country data country code 2 ', selectedCountryData.iso2);
      // console.log('number ', number);
      let token = this.$store.state.token;
      let headersObject = new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      });

      let objData = {
        code: code,
      };
      const formData = Object.keys(objData)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(objData[key]))
        .join('&');
      let apiUrl = process.env.VUE_APP_API_URL;
      await fetch(apiUrl + 'user/phone-verify', {
        method: 'POST',
        headers: headersObject,
        body: formData,
      })
        .then((response) => response.json())
        .then((resData) => {
          console.log('Success:', resData);
          let messages = resData.messages;
          if (resData.status == 'error') {
            self.$data.result.status = resData.status;
            if (messages.common.length > 0) self.$data.error.common = messages.common;
            else self.$data.error.common = [];
            //eslint-disable-next-line
            if (messages.hasOwnProperty('verifyCode')) {
              self.$data.error.verifyCode = messages.verifyCode;
            }
            console.log('self.$data ', self.$data);
            self.ajax.isVerifying = false;
            return false;
          }

          self.$data.result.status = resData.status;
          //eslint-disable-next-line
          if (messages.hasOwnProperty('common')) {
            self.$data.error.common = messages.common;
          }
          self.$store.commit('changePhoneVerify', 1);
          self.$store.commit('changeActive', 1);
          window.location.replace('/');
          self.ajax.isVerifying = false;
        })
        .catch(function (err) {
          console.log('error', err);
          self.ajax.isVerifying = false;
        });
    },
  },
  async mounted() {
    //from your component
    if (this.$store.state.active == 1) {
      this.$router.push('/');
      return;
    }
    if (this.$store.state.email_verify == 0) {
      this.$router.push('/email-verify');
      return;
    }

    // const inputMobileNumber = document.querySelector('#mobileNumber');
    const inputMobileNumber = document.getElementById('mobileNumber');
    intlTelInput(inputMobileNumber, {
      separateDialCode: true,
    });
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../node_modules/intl-tel-input/build/css/intlTelInput.css';
</style>
