<template>
  <div class="steps w-100">
    <progress id="progress" :value="$props.progress" max="100"></progress>
    <div class="step-item text-start">
      <button
        class="step-button text-center"
        type="button"
        data-toggle="collapse"
        data-target="#collapseOne"
        :aria-expanded="$props.email"
        aria-controls="collapseOne"
        title="Required"
      >
        <i class="fa fa-mail-bulk"></i>
      </button>
      <div class="step-title label-require" title="Required">Email verify</div>
      <small class="text-muted">Or social network account verify</small>
    </div>
    <div class="step-item text-end">
      <button
        class="step-button text-center collapsed"
        type="button"
        data-toggle="collapse"
        data-target="#collapseTwo"
        :aria-expanded="$props.phone"
        aria-controls="collapseTwo"
        title="Required"
      >
        <i class="fa fa-phone"></i>
      </button>
      <div class="step-title label-require" title="Required">Phone verify</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VerifySteps',
  props: {
    progress: {
      type: Number,
      default: 0,
    },
    phone: {
      type: Boolean,
      default: false,
    },
    email: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
/* CSS */
.steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  position: relative;
}

.step-button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  background-color: #ecf0f1;
  transition: 0.4s;
}

.step-button[aria-expanded='true'] {
  width: 60px;
  height: 60px;
  background-color: rgb(41, 115, 115);
  color: #fff;
}

.done {
  background-color: rgb(41, 115, 115);
  color: #fff;
}

.step-item {
  z-index: 10;
  text-align: center;
}

#progress {
  -webkit-appearance: none;
  position: absolute;
  width: 95%;
  z-index: 5;
  height: 10px;
  margin-left: 18px;
  margin-bottom: 18px;
}

/* to customize progress bar */
#progress::-webkit-progress-value {
  background-color: rgb(41, 115, 115);
  transition: 0.5s ease;
}

#progress::-webkit-progress-bar {
  background-color: #ecf0f1;
}
</style>
